<template>

    <v-dialog
        v-model="dialog"
        width="900"
        hide-overlay
    >
        <v-card>
            <v-card-actions>
                <v-spacer/>
                <v-btn color="primary" @click="print">Imprimir</v-btn>
                <v-btn color="primary" @click="dialog=false">Cerrar</v-btn>
            </v-card-actions>
            <v-card-text>
            <div class="text-h6">Imprimir movimientos del día {{estaFecha}} del area de {{areaText}}</div>

                <div id="printMovs" >

                    <div class="onlyPrint">
                        <div class="encabezado">
                            <div>
                                <div class="head1">Hospital de la Niñez Oaxaqueña</div>
                                <div class="head2">Movimientos del día {{estaFecha}} del area de {{areaText}}</div>
                            </div>
                            <div style="text-align: right;">
                                <img
                                    width="70"
                                    src="/images/header-03.png"
                                />
                            </div>

                        </div>

                        <table style="width:100%">
                            <tr class="htabla">
                                <th>Cama</th>
                                <th>C.I.P.</th>
                                <th>Paciente</th>
                                <th>Edad</th>
                                <th>Diagnóstico</th>
                                <th>Viene de</th>
                                <th>Ingreso</th>
                                <th>Egreso</th>
                                <th>Pasa a</th>
                                <th>Observaciones</th>
                            </tr>

                            <tr class="tabla" v-for="item in datos" :key="item.id" height="30">
                                <td>{{item.cama}}</td>
                                <td>{{item.paciente}}</td>
                                <td>{{item.pacienteNombre}}<br/>{{item.pacienteCurp}}</td>
                                <td style="text-align: center;">{{item.pacienteEdad}}<br/>{{item.pacienteFechaNacimiento}}</td>
                                <td>{{item.motivo}}</td>
                                <td style="text-align: center;"><span v-if="item.ingreso !==1 && item.ingreso !== 0">{{item.ingreso}}<br/>{{item.dateCreated}}</span></td>
                                <td style="text-align: center;"><span v-if="item.ingreso == 1">{{item.dateCreated}}</span></td>
                                <td style="text-align: center;"><span v-if="item.aunInternado == -1 && item.movimientoTipo == 4">{{item.dateEnd}}</span></td>
                                <td style="text-align: center;"><span v-if="item.aunInternado == -1 && item.movimientoTipo == 3">{{item.dateEnd}}<br/>{{item.servicioDestino[0]}}</span></td>
                                <td><span v-if="item.aunInternado == -1 && item.movimientoTipo == 1">Cambio de cama</span></td>
                            </tr>
                            
                        </table>
                        <div style="margin-top:30px;"/>
                        <table style="width:50%">
                            <tr class="htabla">
                                <th>Ingresos</th>
                                <th>{{totales.ingresos}}</th>
                                <th>Egresos</th>
                                <th>{{totales.egresos}}</th>
                                <th>Pases de</th>
                                <th>{{totales.pasesDe}}</th>
                                <th>Pases a</th>
                                <th>{{totales.pasesA}}</th>
                            </tr>
                        </table>

                        <!-- Salto de página -->
                        <div style="display:block; page-break-before:always;"></div>
                        <!-- Salto de página -->

                        <div class="encabezado">
                            <div>
                                <div class="head1">Hospital de la Niñez Oaxaqueña</div>
                                <div class="head2">Actividades realizadas el {{estaFecha}} en el área de {{areaText}}</div>
                            </div>
                            <div style="text-align: right;">
                                <img
                                    width="70"
                                    src="/images/header-03.png"
                                />
                            </div>

                        </div>
                        <div v-for="itm in totalesProc" :key="itm.id" style="margin-bottom:10px;">
                            <table style="width:100%; background-color:#BDBDBD;">
                                <tr>
                                    <th style="width:10%"><div class="htabla_2">Expediente</div><div class="htabla_mini2">{{itm.cip}}</div></th>
                                    <th style="width:30%"><div class="htabla_2">Paciente</div><div class="htabla_mini2">{{itm.pacienteNombre}}</div></th>
                                    <th style="width:10%"><div class="htabla_2">C.U.R.P.</div><div class="htabla_mini2">{{itm.curp}}</div></th>
                                    <th style="width:10%"><div class="htabla_2">Edad</div><div class="htabla_mini2">{{itm.edad}} ({{itm.fechaNacimiento}})</div></th>
                                    <th style="width:40%"><div class="htabla_2">Diagnóstico</div><div class="htabla_mini2">{{itm.motivo}}</div></th>
                                </tr>
                            </table>

                            <table style="width:100%">
                                <tr class="htabla_2" style="background-color:#42A5F5;">
                                    <th style="width:80%">MATUTINO (Procedimientos: {{itm.procsMat.length}})</th>
                                    <th>Tiempo invertido: {{calculaTotalTurno(itm.procsMat)}} mins</th>
                                </tr>
                                <tr v-for="itmp in itm.procsMat" :key="itmp.id" class="htabla_mini2">
                                    <th style="width:80%; text-align: left;">{{itmp.procedimientoNombre}}<br/>{{itmp.observaciones}}</th>
                                    <th>{{itmp.tiempo}}</th>
                                </tr>
                            </table>

                            <table style="width:100%">
                                <tr class="htabla_2" style="background-color:#66BB6A;">
                                    <th style="width:80%">VESPERTINO (Procedimientos: {{itm.procsVes.length}})</th>
                                    <th>Tiempo invertido: {{calculaTotalTurno(itm.procsVes)}} mins</th>
                                </tr>
                                <tr v-for="itmp in itm.procsVes" :key="itmp.id" class="htabla_mini2">
                                    <th style="width:80%; text-align: left;">{{itmp.procedimientoNombre}}<br/>{{itmp.observaciones}}</th>
                                    <th>{{itmp.tiempo}}</th>
                                </tr>
                            </table>

                            <table style="width:100%">
                                <tr class="htabla_2" style="background-color:#EF9A9A;">
                                    <th style="width:80%">NOCTURNO (Procedimientos: {{itm.procsNoc.length}})</th>
                                    <th>Tiempo invertido: {{calculaTotalTurno(itm.procsNoc)}} mins</th>
                                </tr>
                                <tr v-for="itmp in itm.procsNoc" :key="itmp.id" class="htabla_mini2">
                                    <th style="width:80%; text-align: left;">{{itmp.procedimientoNombre}}<br/>{{itmp.observaciones}}</th>
                                    <th>{{itmp.tiempo}}</th>
                                </tr>
                            </table>


                            
                            

                        </div>
                        
                        

                    </div>
                </div>


            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    
    props:{
        dialogImprime:Boolean,
        datos:Array,
        totales:Object,
        estaFecha:String,
        areaText:String,

        totalesProc: Array,
        
    },

    data:()=>({
        output: null,
    }),
    methods:{
        async print () {
            await this.$htmlToPaper('printMovs',{
                styles:[
                    '/css/censo.css'
                ],
            })
        },
        calculaTotalTurno(datos){
            let total = datos.map(a => a.tiempo).reduce((a, b) => a + b, 0)
            return total
        }
        
    },
    computed:{
        dialog:{
            get(){
                return this.dialogImprime
            },
            set(val){
                this.$emit('update:dialogImprime',val)
            },
        },
        
    },
}
</script>

<style>
@media screen {
    @page {
        size: landscape;
    }
    .onlyPrint {
        display: none;
    }
}
</style>